
.content-body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .header {
        padding: 0 20px;
    }
    .steps-wrapper {
        padding: 20px;
    }
    .create-content{
        flex: 1;
        padding: 0 20px;
        .create-content-top{
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            .create-content-left{
                /*::v-deep.el-button--primary.is-plain {*/
                /*    color: #1CB064;*/
                /*    background: transparent;*/
                /*    border-color: #a4dfc1;*/
                /*}*/
            }
            .create-content-right{

                display: flex;
                align-items: center;
                .label-name{
                    min-width: 100px;
                }
            }
            .name-input {
                width: 325px;
                ::v-deep .el-input__inner {
                    border-radius: 0;
                }
            }
        }
        .add-footer{
            margin-top: 20px;
        }
        .success-content{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 140px;
            .text{
                font-size: 16px;
                color: #333;
                font-weight: bold;
            }
        }
    }
    .create-table {
        height: 1%;
        display: flex;
        flex-direction: column;
    }
    .bottom-btn{
        text-align: center;
        padding: 20px;
    }
}
.add-item {
    display: flex;
    align-items: center;
    ::v-deep .el-form-item__label {
        margin-right: 10px;
        margin-bottom: 0;
        color: #666;
    }
    ::v-deep .el-form-item__content {
        flex: 1;
        width: 1%;
        margin-left: 0 !important;
        .el-select {
            width: 100%;
        }
    }
}
